<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="accountRules"
      tag="form"
    >
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Old Password"
              rules="required"
            >
              <b-form-group
                label="Old Password"
                label-for="account-old-password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-old-password"
                    v-model="old_password"
                    name="old-password"
                    :type="passwordFieldTypeOld"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Old Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconOld"
                      class="cursor-pointer"
                      @click="togglePasswordOld"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="New Password"
              rules="required"
            >
              <b-form-group
                label-for="account-new-password"
                label="New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-new-password"
                    v-model="password"
                    :type="passwordFieldTypeNew"
                    name="new-password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <validation-provider
              #default="{ errors }"
              name="Retype New Password"
              rules="required|confirmed:New Password"
            >
              <b-form-group
                label-for="account-retype-new-password"
                label="Retype New Password"
              >
                <b-input-group class="input-group-merge">
                  <b-form-input
                    id="account-retype-new-password"
                    v-model="password_confirmation"
                    :type="passwordFieldTypeRetype"
                    name="retype-password"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Retype New Password"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click="validationForm"
            >
              Update Password
            </b-button>
          </b-col>
        <!--/ buttons -->
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      old_password: '',
      password: '',
      password_confirmation: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeOffIcon':'EyeIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeOffIcon':'EyeIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeOffIcon':'EyeIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm() {
      const self = this
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            self.$http.post('/change-password', {
              old_password: self.old_password,
              password: self.password,
              password_confirmation: self.password_confirmation,
            })
              .then(res => {
                if (res.data.success) {
                  self.old_password = ''
                  self.password = ''
                  self.password_confirmation = ''
                  self.$refs.accountRules.reset()
                  // self.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: 'Password Updated',
                  //     icon: 'BellIcon',
                  //     text: res.data.message,
                  //     variant: 'success',
                  //   },
                  // })
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password Updated',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
                } else {
                  // self.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: 'Change Password Failed',
                  //     icon: 'BellIcon',
                  //     text: res.data.message,
                  //     variant: 'danger',
                  //   },
                  // })
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Change Password Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              })
              .catch(error => {
                // Do something on error
                if (error.response.status === 422) {
                  if (typeof error.response.data.errors !== 'undefined') {
                    const keys = Object.keys(error.response.data.errors)
                    const values = Object.values(error.response.data.errors)
                    for (let i = 0; i <= keys.length; i += 1) {
                      if (error.response.data.errors.hasOwnProperty.call(values, i)) {
                        // this.$toast({
                        //   component: ToastificationContent,
                        //   props: {
                        //     title: 'Change Password Failed',
                        //     icon: 'BellIcon',
                        //     text: values[0][0],
                        //     variant: 'danger',
                        //   },
                        // })
                        this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Change Password Failed',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: values[0][0],
                      },
                    }, {
                      timeout: 2000, 
                      position: 'bottom-center',
                      toastClassName:"radius-8 error-info ",
                      hideProgressBar : true,
                    });
                      }
                    }
                  }
                } else {
                  // this.$toast({
                  //   component: ToastificationContent,
                  //   props: {
                  //     title: 'Change Password Failed',
                  //     icon: 'BellIcon',
                  //     text: 'Oops! Something Went Wrong',
                  //     variant: 'danger',
                  //   },
                  // })
                  this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Change Password Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: "Oops! Something Went Wrong",
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
                }
              })
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
