<template>
  <b-card>
    <!-- form -->
    <b-form>
      <b-row>
        <!--/ bio -->
        <!-- buttons -->
          <b-col cols="6">
            <button
              type="button"
              class="btn btn-info"
              style="height: 40px"
              @click="loginWithZoom"
              :disabled='isZoomAuthDisabled'
            >
              Authorize Zircly to use My Zoom
            </button>
          </b-col>
        <!-- buttons -->
          <b-col cols="6">
            <button
              type="button"
              class="btn btn-info"
              style="height: 40px" 
              :disabled='isZoomDeleteDisabled'
              @click="logOutWithZoom"
            >
              Deauthorize Zircly
            </button>
          </b-col>
      </b-row>
      
    </b-form>
  </b-card>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from "@/auth/jwt/useJwt";

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    zoomData: {
      type: Object,
      default: () => {},
    },
  },
  data(){
    return{
      newUserData: this.$cookies.get('userData'),
      zoom_access: this.checksAccessToken(),
    }
  },
  created() {
    console.log("zoom token present");
    console.log(this.checksAccessToken());
  },
  methods: {
    checksAccessToken() {
      if (
        this.$cookies.get('userData').zoom_linked === false
      ) {
        return false;
      }
      return true;
    },
    loginWithZoom() {
      const self = this;
      var j = {
        user:self.newUserData.hashid,
        email:self.newUserData.email,
        tenant:window.location
      }
      var url =
        "https://zoom.us/oauth/authorize?response_type=code&client_id=" +
        process.env.VUE_APP_ZOOM_CLIENT_ID +
        "&redirect_uri=" +
        process.env.VUE_APP_ZOOM_REDIRECT_URI +
        "&state=" +JSON.stringify(j);

      if (!self.zoom_access || !self.newUserData.zoom_linked) {
        var win = window.open(url, "Google", "width=600,height=600");

        var timer = setInterval(function () {
          if (win.closed) {
            clearInterval(timer);
            useJwt.getUserInfo().then((res) => {
              //localStorage.setItem("userData", JSON.stringify(res.data.data));
              this.$cookies.set("userData",res.data.data,60 * process.env.VUE_APP_LOGIN_TIMEOUT);
              self.newUserData = this.$cookies.get('userData');
              if (self.newUserData.zoom_linked) {
                self.zoom_access = true;
                // self.$toast({
                //   component: ToastificationContent,
                //   props: {
                //     title: "Your Zoom Details Added to Zircly",
                //     icon: "BellIcon",
                //     variant: "success",
                //     text: res.data.message,
                //   },
                // });
                this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Your Zoom Details Added to Zircly',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
              }
            })
            .catch((error) => {
              // self.$toast(
              //   {
              //     component: ToastificationContent,
              //     props: {
              //       title: "Oops! Authorize Zircly to Zoom Failed",
              //       icon: "BellIcon",
              //       variant: "danger",
              //       text: error,
              //     },
              //   });
              this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Authorize Zircly to Zoom Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
            });
          }
        }, 1000);
      } else {
        self.zoom_access = false;
      }
    },
    logOutWithZoom() {
      axios.get("/delete-zoom", { })
          .then((res) => {
            useJwt.getUserInfo().then((res) => {
              //localStorage.setItem("userData", JSON.stringify(res.data.data));

              this.$cookies.set("userData",res.data.data,60 * process.env.VUE_APP_LOGIN_TIMEOUT);
              this.newUserData = this.$cookies.get('userData');
              if (this.newUserData.zoom_linked) {
                this.zoom_access = true;
              }else{
                this.zoom_access = false;
              }
            });

            // this.$toast({
            //   component: ToastificationContent,
            //   props: {
            //     title: "Your Zoom Details removed from Zircly",
            //     icon: "BellIcon",
            //     variant: "success",
            //     text: res.data.message,
            //   },
            // });
            this.$toast({
              component: ToastificationContent,
              props: {
                title: '"Your Zoom Details removed from Zircly',
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          // this.$toast(
          //   {
          //     component: ToastificationContent,
          //     props: {
          //       title: "Oops! Delete Zoom Data Failed",
          //       icon: "BellIcon",
          //       variant: "danger",
          //       text: error,
          //     },
          //   });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Delete Zoom Data Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
  },
  computed:{
      isZoomAuthDisabled() {
        if(this.zoom_access == false){
          return false;
        }else{
          return true;
        }
      },
      isZoomDeleteDisabled() {
        if(this.zoom_access == false){
          return true;
        }else{
          return false;
        }
      },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
